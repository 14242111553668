import React from "react";

const ViewCareTeamBox = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <div className="careteam_box13 carebox_back5 text-center pt-4 mb-4">
            <h1 className="mt-3">2K</h1>
            <h5>Appointments</h5>
          </div>
        </div>
        <div className="col-md-3">
          <div className="careteam_box13 carebox_back6 text-center pt-4 mb-4">
            <h1 className="mt-3">5K</h1>
            <h5>Total Patients</h5>
          </div>
        </div>
        <div className="col-md-3">
          <div className="careteam_box13 carebox_back7 text-center pt-4 mb-4">
            <h1 className="mt-3">12</h1>
            <h5>Groups</h5>
          </div>
        </div>
        <div className="col-md-3">
          <div className="careteam_box13 carebox_back8 text-center pt-4 mb-4">
            <h1 className="mt-3">8</h1>
            <h5>Programs</h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCareTeamBox;
