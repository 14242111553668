const DailyJournalEmptyEatTable = () => {
  return (<>
    <tr>
      <td className="table_title5">Ate:</td>
      <td>--</td>
    </tr>
    <tr>
      <td className="table_title5">Time:</td>
      <td>--</td>
    </tr>
    <tr>
      <td className="table_title5">Place:</td>
      <td>--</td>
    </tr>
    <tr>
      <td className="table_title5">Hunger:</td>
      <td>--</td>
    </tr>
    <tr>
      <td className="table_title5">Any Reactions:</td>
      <td>No Reaction</td>
    </tr>
  </>
  )
}

export default DailyJournalEmptyEatTable;