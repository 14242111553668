export const lifeStyleCategoryList = {
  sleep: 'sleep',
  connect: 'connect',
  relax: 'relax',
  takeCare: 'takeCare',
  reflect: 'reflect',
}

export const fontSize = {
  font17: 17,
  font19: 19,
  font20: 20,
}
export const recipeTags = [
  'Low carb', 'High protein', 'Diabetic Diet', 'Detox', 'Vegetarian', 'Vegan', 'Mediterranean', 'Pelio', 'Keto', 'American', 'Mexican', 'Italian', 'Japanese', 'Chinese', 'Asian', 'Thai', 'Indian', 'Gluten Free', 'AIP', 'Elimination Diet', 'Low Sugar', 'Low Protein', 'Low Fat', 'Hight Fat', 'Raw Food', 'Raw Meat', 'DASH diet', 'Atkins diet', 'Plant based', 'Low sodium', 'Renal Diet', 'Mayo Clinic Diet', 'Dairy Free'
]
export const formList1 = [
  'Name', 'Preferred first name', 'Middle Name', 'Contact Information', 'Address', 'Date of Birth', 'Gender', 'Pronouns', 'Relationship Status', 'Occupation', 'Referral'
]


export const exerciseCategory = [
  'Cardio', 'Pilates', 'Weight lifting', 'HIIT', 'Stretching', 'Cycling',
  'Restistance training', 'Dance', 'Barre', 'Circuit training'
]


export const formList2 = [
  'Gender', 'Pronouns', 'Relationship Status', 'Occupation', 'Referral'
]
export const groupMembers = [
  'John', 'Alon', 'Diane', 'Blake'
]
export const blogKeyWord = [
  'general health', 'heart health', 'diabetes', 'hypertension', 'gut health', 'mental health', 'women’s health', 'men’s health', 'stress management', 'weight management', 'sleep hygiene', 'asthma', 'infections', 'immunity', 'hormones', 'allergies', 'environmental health'
]

export const emptyListText = {
  carePlanGoal: 'No goals added',
  carePlanTask: 'No tasks added',
  selectCarePlan: 'Please select care plan',
  emptyBlog: 'Please add blogs...',
  emptyLifestyle: 'No lifestyle associated with this user',
  selectLifeStyle: 'Please select life style',
  lifestyleAffirmationEmpty: 'Affirmation is not selected yet',
  lifestyleCheckListEmpty: 'Checklist is not selected yet',
  selectActiveProgram: 'Please select active program',
  noActiveProgram: 'Please assign active program...',
  noCarePlan: 'Please assign care plan...',
  noData: 'No data here...',
  addNewTask: 'Add new task...',
  addNewGoal: 'Add new goal...',
  addNewFile: "Add new file...",
  addNewModules: "Add new modules...",
  addNewSession: "Add new session...",
  addNewForums: "Add new forums...",
  noApprovedList: `No Approved List...`,
  noWaitingList: `No waiting list...`,
  noAnyActiveProgram: `Please add active program...`,
  noAnyGroup: `Please add group...`,
  noAnyCarePlan: `Please add care plan...`,
  noAnySupplements: `Please add supplements...`,
  noAnyMedications: `Please add medications...`,
  noGoalsFound: `Please add goals...`,
  clickGoalsToViewTask: `Click Goals to view Tasks`,
  noAnyActiveMember: `Add new active member...`,
  noNotification: "Notifications Empty",
  pleaseSelectMealPlan: 'Please select meal plan',
  pleaseAddMealPlan: 'Please add meal plan',
  pleaseAddForms: 'Please add forms',
  pleaseSelecFitnessPlan: 'Please select fitness plan',
  pleaseAddFitnessPlan: 'Please add fitness plan',
  pleaseAddAffirmations: 'Please add affirmations'
}

export const toastMsg = {
  createGroup: `Group created successfully.`,
  updateGroup: `Group updated successfully.`,
  newComment: `Added new comment.`,
  newReply: `Added new reply.`,
  createPost: `Post created successfully.`,

  addBlog: `Blog created successfully.`,
  updateBlog: `Blog updated successfully.`,
  deleteBlog: `Blog deleted successfully.`,

  addLifeStyleTemplate: `Lifestyle added successfully.`,
  addAffirmation: `Affirmation added successfully.`,
  deleteAffirmation: `Affirmation deleted successfully.`,
  addCheckList: `Checklist added successfully.`,
  deleteCheckList: `Checklist deleted successfully.`,
  changeLifeStyleTemplate: `Lifestyle assigned successfully.`,

  programAssigned: 'Program assigned successfully.',
  meealAssigned: 'Meal plan assigned successfully.',
  changeMealPlanTemplate: `Meal plan assigned successfully.`,
  addMealPlan: `Meal plan added successfully.`,

  patientAdded: 'Patient added successfully.',
  patientNotAdded: 'Patient not added.',

  doctorAdded: 'Doctor added successfully.',
  doctorNotAdded: 'Patient not added.',

  createCarePlans: `Care plan created successfully.`,
  updateCarePlan: `Care plan updated successfully.`,

  carePlanAssigned: `Care plan assigned successfully to user.`,

  reminderCreated: `Reminder created successfully.`,
  reminderDeleted: `Reminder deleted successfully.`,

  userLogged: `User logged in successfully.`,
  userFailed: `User logged in failed.`,

  accountDoesNotExist: `Account with this email does not exist.`,

  tokenExpire: "Token expireded.",

  errorMssg: `Some error occurred.`,

  selectGoal: "Please select goal to assign task.",
  fillTask: "Please select task for.",

  programNotAvailable: "Program not available.",

  programEnd: "Program is finished.",

  updateMealPlan: `Meal plan updated successfully.`,

}

export const dailyJournalReflectQuestions = [
  'I Get Adequate Sleep Every Night',
  'I Am Mindful Of What I Eat At All Times',
  'I Have A Growth Mindset Attitude',
  'I Do My Best Effort To Stay Hydrated',
  'I Am Focused On Improving My Health',
  'I Respond Calmly To Stressful Events',
  'I Make Every Effort To Reduce Toxins',
  // 'Reflect On Your Day. What Are Some Things You Are Grateful For? Did You Discover Something New About Yourself? What Made You Smile?'
]

export const defaultCategory = ['Health', 'Nutrition', 'Lifestyle', 'Fitness', 'Daily', 'Inspirational'];
export const timezone = [
  {
    value: "AST",
    label: "Atlantic Standard Time - (AST)"
  },
  {
    value: "EST",
    label: "Eastern Standard Time - (EST)"
  },
  {
    value: "CST",
    label: "Central Standard Time - (CST)"
  },
  {
    value: "MST",
    label: "Mountain Standard Time - (MST)"
  },
  {
    value: "PST",
    label: "Pacific Standard Time - (PST)"
  },
  {
    value: "AKST",
    label: "Alaskan Standard Time - (AKST)"
  },
  {
    value: "HST",
    label: "Hawaii-Aleutian Standard Time - (HST)"
  },
  {
    value: "UTC-11",
    label: "Samoa standard time - (UTC-11)"
  },
  {
    value: "UTC+10",
    label: "Chamorro Standard Time - (UTC+10)"
  }
];
export const supplementList = [
  {
    "id": "0720a24a-8100-4754-ae68-2a9a0d819bfa",
    "name": "vitamins"
  },
  {
    "id": "36962b17-e035-4927-a140-d52572b55f27",
    "name": "minerals"
  },
  {
    "id": "75f77489-e2ae-43a7-bd3a-d52bb5a2e836",
    "name": "herbs"
  },
  {
    "id": "3b2ecc7f-91dc-4b0c-a33b-95482432c055",
    "name": "omegas"
  },
  {
    "id": "e0243c7c-388f-4bda-b8af-7d7f52f23b09",
    "name": "antioxidants"
  },
  {
    "id": "73e73e6f-1913-4902-8394-ce8d35808ca0",
    "name": "enzymes"
  },
  {
    "id": "81f71216-111e-4f3c-bf7d-875a7b5a2864",
    "name": "probiotics"
  },
  {
    "id": "1a8570f6-2180-47e9-a250-020c99c76b7e",
    "name": "greens"
  },
  {
    "id": "2d99e971-a6eb-441d-9346-a8159c8217fe",
    "name": "glandulars"
  },
  {
    "id": "db4d519d-e314-4ab5-bc9c-8f413e581b2a",
    "name": "amino acids"
  },
  {
    "id": "84a09f15-3d3c-4a79-8b60-f687079f104d",
    "name": "mushrooms"
  },
  {
    "id": "34eeecec-6ed0-464e-bdc9-43203b4e53bf",
    "name": "fiber"
  },
  {
    "id": "3df39c5c-7ee6-433d-b9b3-43019de6c8e1",
    "name": "homeopathics"
  },
  {
    "id": "82d233c1-e249-46f3-a377-0cc3d2d1cd1b",
    "name": "b vitamins"
  },
  {
    "id": "51bd14c1-dd08-4ebc-9904-7e34a5c2a6ea",
    "name": "protein"
  },
  {
    "id": "462850b2-a550-423e-9b51-edae9f1bb6ed",
    "name": "multivitamins"
  },
  {
    "id": "6d4ae155-7ff9-490f-86cd-d9f59a2eb4df",
    "name": "multiminerals"
  },
  {
    "id": "1b8ef97a-b4f8-4744-a4ce-06f080bf5c0a",
    "name": "b complex"
  },
  {
    "id": "a85a9010-5902-47c4-bf85-9de29d438977",
    "name": "tcm herb"
  },
  {
    "id": "352deda0-6ee6-4daf-9c26-b6a6794f2bcf",
    "name": "prebiotics"
  }
]
export const setUserGoalQuestion = [
  "what exactly do I want to achieve with my gut health?",
  "How will I know when I have achieved my goals?",
  "Are my goals realistic and how will I accomplish them?",
  "Why are my goals important to me?",
  "What is my deadline for reaching my goals?",
]