import React from "react";

const CarePlanFile = () => {
  return (
    <>
      <div className="careplan_filecard mt-3">
        <div className="row">
          <div className="col-md-1 text-center">
            <img src="images/nature.png" alt="profile" />
          </div>
          <div className="col-md-10">
            <p className="rating_parag1">Jennifer Connecy</p>
            <p className="rating_parag2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
         
        </div>
      </div>

      <div className="careplan_filecard mt-3">
        <div className="row">
          <div className="col-md-1 text-center">
            <img src="images/nature.png" alt="profile" />
          </div>
          <div className="col-md-10">
            <p className="rating_parag1">Jennifer Connecy</p>
            <p className="rating_parag2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
         
        </div>
      </div>


      <div className="careplan_filecard mt-3">
        <div className="row">
          <div className="col-md-1 text-center">
            <img src="images/nature.png" alt="profile" />
          </div>
          <div className="col-md-10">
            <p className="rating_parag1">Jennifer Connecy</p>
            <p className="rating_parag2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
         
        </div>
      </div>



      <div className="col-md-12 text-center">
        <button className="upload_new_file12">upload new file</button>
      </div>
    </>
  );
};

export default CarePlanFile;
